@import "../../config/colors.scss";

.footer {
  color: black;
  margin: 2% 2%;
  justify-content: flex-end;
  margin-top: auto;
  float: right;
}

.contact {
  color: #000;
  align-items: center;
  width: 35%;
  padding: 1%;
  margin: auto;
}

.contact-items {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.link-icon {
  padding: 5% 10%;
  color: #000;
}

.link-icon:hover {
  cursor: pointer;
  color: $secondary-color;
}

@media only screen and (max-width: 900px) {
  .link-icon {
    padding: 1% 10%;
  }
  
  .footer {
    background-color: $background-color;
    width: 100%;
    margin: 0;
    margin-right: 0;
  }
}