@import "../../config/colors.scss";

.container {
  width: 100%;
}

.grid {
  display: flex;
  width: 100%;
  margin: auto;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  &:nth-child(1n) {
    flex-basis: 100%;
}
}

.imageContainer {
  margin: 5px 5px 3px 5px;
  width: 40%;
  height: auto;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  &:hover .overlay {
    opacity: 1;
  }
}

.img__description_layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(black, .5);
  color: #fff;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  /* transition effect. not necessary */
  transition: opacity .2s, visibility .2s;
}

.imageContainer:hover .img__description_layer {
  visibility: visible;
  opacity: 1;
}

.img__description {
  transition: .2s;
  font-family: fugaz;
  font-size: larger;
  transform: translateY(1em);
}

.imageContainer:hover .img__description {
  transform: translateY(0);
}

.image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.overlay {
  position: absolute;
  // top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 15%;
  margin: auto;
  opacity: 0;
  text-align: center;
  transition: .5s ease;
  background-color: rgba(0,0,0,0.5);
}

.imageText {
  color: white;
  font-size: 20px;
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  text-align: center;
}

.feature-container {
  justify-content: center;
  display: flex;
  width: 100%;
  height: auto;
  margin-bottom: 2%;
}

.feature-image {
  width: 45%;
  height: auto;
  object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  &:hover {
    cursor: pointer;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    margin: 0 0;
    padding-bottom: 10%;
  }

  .grid {
    flex-wrap: nowrap;
    flex-direction: column;
    display: block;
  }

  .imageContainer {
    margin: 0;
    width: 100%;
    padding-bottom: 2%;
  }
}