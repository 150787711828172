@import "../../config/colors.scss";

.container {
  width: 100vw;
}

.header-name {
  font-family: antro;
  font-size: 50px;
  margin-bottom: 0;
  color: $primary-color
}

.sub-name {
  letter-spacing: 5px;
  font-weight: 100;
  margin-top: 1%;
  font-size: larger;
  font-family: montserrat;
  color: $medium-grey;
}

.option {
  color: $dark-grey;
  text-decoration: none;
  padding: 3%;
  font-size: 18px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  // TODO: quicksand, light 300
  &.activeOption {
    color: $primary-color;
  }
  &:hover {
    color: $secondary-color;
  }
}


.header {
  padding: 1% 10% 5% 10%;
  display: block;
  text-align: center;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 700px) {
  .header-name {
    font-size: 30px;
  }
  .sub-name {
    letter-spacing: 2px;
    font-size: large;
  }
}