@import "../../config/colors.scss";


.contactContainer {
  margin: 0 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5%;
}

.bioSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  height: 100%
}

.bioImageContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 5%;
  width: 50%;
  max-height: 20rem
}
.bioImage {
  width: 12rem;
  filter: grayscale(100%);
}

.rowBioImages {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 2rem;
  width: 100%;
}

.bio-text {
  color: black;
  font-family: spectral;
  padding: 7rem;
  width: 50%
}
.contact-info {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 10%;
}

.contact-info a {
  color: #111;
  padding-bottom: 10%;
  padding-top: 10%;
  text-decoration: none;
  &:hover {
    color: $primary-color;
  }
}

.logo {
  width: 40%;
  padding-top: 15%;
}

@media only screen and (max-width: 600px) {
  .contactContainer {
    margin: 0 5%;
    margin-bottom: 20%;
  }

  .logo {
    padding-top: 10%;
  }

  .bioImage {
    width: 30%
  }

  .bio-text { 
    padding: 0;
    align-items: center;
    width: 100%
  }
  .bioImageContainer {
    width: 100%
  }
  .bioSection {
    flex-direction: column-reverse;
    justify-content: space-between;
    flex: 1;
  }

  .laurelContainer {
    flex-wrap: wrap;
  }
  .laurel {
    height: 100%;
    width: 50%;
    padding-bottom: 1rem;
  }  
}