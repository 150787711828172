@import "../../config/colors.scss";

.commercialContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 5rem 0 5rem;
    align-items: center;
}

.comSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 125rem;
    justify-content: center;
}

.cTitle {
    font-family: fugaz;
    margin-bottom: 0.5rem;
    color: $black;
}

.cVideos {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}

.cFeature {
    width: 50%;
}

.cVid{
    width: 20%;
}


@media only screen and (max-width: 600px) {
    .commercialContainer {
        margin: 0 1rem 0 1rem;
    }
    .cVideos {
        flex-direction: column;
    }

    .cFeature {
        width: 100%;
    }
    .cVid{
        width: 100%;
        margin-bottom: 1rem;
    }
    
    
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .commercialContainer {
        margin: 0 3rem 0 3rem;
        align-items: center;
    }
    .cVideos {
        flex-direction: column;
    }

    .cFeature {
        width: 80%;
    }
    .cVid{
        width: 80%;
        margin-bottom: 1rem;
    }
  }