@import url('https://fonts.googleapis.com/css?family=Spartan:400,700&display=swap');
@import "./config/colors.scss";

html {
  overflow-x: hidden;
  background-color: $background-color;
  color: white;
}

body, #root{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@font-face {
  font-family: chapasa;
  src: url('./assets/fonts/chapaza.ttf');
}


@font-face {
  font-family: antro;
  src: url('./assets/fonts/antro_vectra.otf');
}

@font-face {
  font-family: montserrat;
  src: url('./assets/fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: bambi;
  src: url('./assets/fonts/x_bambi.ttf');
}

@font-face {
  font-family: fugaz;
  src: url('./assets/fonts/FugazOne-Regular.ttf');
}

@font-face {
  font-family: kosugi;
  src: url('./assets/fonts/KosugiMaru-Regular.ttf');
}

@font-face {
  font-family: spectral;
  src: url('./assets/fonts/Spectral-Regular.ttf');
}

div {
  font-family: myFirstFont;
}

p {
  font-family: chapasa;
}