@import "../../config/colors.scss";

.landing {
  height: 100vh;
  width: 100vw;
  background-color: $background-color;
  display: flex;
  justify-content: center;
  align-items: center;
}

#landingVideo {
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  z-index: 1;
}

.text{
  z-index: 2;
  position: absolute;
  background: rgba(0,0,0,0.4);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: red;
  display: block;
}

.video_overlays {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-size: 1px 3px;
  background-image: url(https://ifoundcodi.com/_jsapps/backdrop/video/assets/interlace.png);
  display: block;
}

/* Add some content at the bottom of the video/page */
.content {
  display: flex; 
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 3;
  text-decoration: none;
}

h2 {
  color: $primary-color;
  font-family: antro, sans-serif;
  font-weight: 700;
  padding: 30px;
  font-size: 75px;
  text-decoration: none;
  border-style: solid;
  border-width: medium;
  border-color: $primary-color;
  transition: 0.5s;
  &:hover {
    color: $background-color;
    background-color: $primary-color;
    border-color:$primary-color;
  }
}

.landing-name {
  text-decoration: none;
  padding: 1rem 2rem;
}