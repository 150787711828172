.feature-container {
    width: 100%
}

.list-image-container {
    margin: 5px 5px 3px 5px;
    width: 48%;
    height: auto;
    position: relative;
}

.list-image {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.list-image-grid {
    display: flex;
    width: 100%;
    margin: auto;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    &:nth-child(1n) {
        flex-basis: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .feature-container {
      margin: 0 0;
      padding-bottom: 10%;
    }
  
    .list-image-grid {
      flex-wrap: nowrap;
      flex-direction: column;
      display: block;
    }
  
    .list-image-container {
      margin: 0;
      width: 100%;
      padding-bottom: 2%;
    }
}
