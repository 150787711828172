@import "../../config/colors.scss";

.navbar {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin: 3rem;
    margin-bottom: 5rem;
    font-size: 15px;
  }

.name-block {
    margin: 10px;
    display: flex;
    align-items: center;
    color: $black;

    &:hover {
      cursor: pointer;
      color: $primary-color;
    }
      
}

.nav-name, .sub-nav {
    font-size: 1.5rem;
    margin: 0;
    font-family: fugaz;
    color: inherit
}


  .navbar a {
    font-family: fugaz;
    float: left;
    border-radius: 5px;
    font-size: inherit;
    color: $black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    margin-right: 4px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-transform: inherit;
  }

  .dropdown .dropbtn {
    font-size: inherit;
    border: none;
    outline: none;
    color: $black;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
  }
  
  
  .dropdown {
    float: left;
    overflow: hidden;
    font-family: fugaz;
    border-radius: 5px;
  }
  
  
  .navbar a:hover, .dropdown:hover, .dropbtn:hover {
    color: $primary-color;
    cursor: pointer;
  }

  .tabs {
    display: flex;
    flex-direction: row;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    border-radius: 5px;
    min-width: 160px;
    z-index: 1;
  }
  
  .dropdown-content a {
    float: none;
    padding: 10px 14px;
    text-decoration: none;
    display: block;
    text-align: left;
    margin-right: 0;
    color: black;
    &:hover {
      color: $primary-color;
      margin-right: 0;
    }
  }
  
  
  .dropdown:hover .dropdown-content {
    display: block;
    border-radius: 5px;
  }

  .hamburger { 
    color: $black;
    display: none
  }

@media only screen 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .navbar {
      margin: 1rem;
    }
    .navbar a, .dropdown .dropbtn {
      display: none;
    }
    .navbar a.hamburger {
      float: right;
      display: block;
    }
  
    .tabs {
      flex-direction: column;
    }
  
    .nav-name, .sub-nav {
      font-size: 1rem;
    }
  
    .responsive .name-block {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .hamburger { 
      display:block
    }
    .navbar.responsive {position: relative;}
    .navbar.responsive .icon {
      position: absolute;
      right: 0;
      top: 0;
    }
    .navbar.responsive a {
      float: none;
      display: block;
      text-align: left;
    }
    .navbar.responsive .dropdown {float: none;}
    .navbar.responsive .dropdown-content {position: relative;}
    .navbar.responsive .dropdown .dropbtn {
      display: block;
      width: 100%;
      text-align: left;
    }
}