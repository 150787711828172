@import "../../config/colors.scss";

.miataContainer {
  margin: 0rem 2rem 5rem 2rem;
}

.error {
  color: red;
}
.success {
  color:$primary-color
}

.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid $primary-color; /* Blue */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
  margin-top: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}