@import "../../config/colors.scss";
 
.videoPageContainer{
      margin: 0 15% 10% 15%;
}

.center {
    margin: auto;
    height: auto;
    text-align: center;
    padding-bottom:2%
}

.videoDescription {
    margin-top: 5%;
    color: $black;
    font-family: spectral;
}

.fakey {
    margin: 0;
    font-family: spectral;
    &.ital {
        font-style: italic ;
    }
    &.end {
        margin-bottom: 20%
    }
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    margin: 0px 5px;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.featuredesc {
    text-align: left;
}


.description {
    margin: 2px 0px;
}

#reelVideo {
    width: 100%
}

@media only screen and (max-width: 600px) {
    .videoPageContainer {
        margin: 0 1% 1% 1%;
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .videoPageContainer {
        margin: 0 5% 0% 5%;
    }
  }