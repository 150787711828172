@import "../../config/colors.scss";

// .laurelContainer {
//     margin-top: 2rem;
//     display: grid;
//     align-items: center;
//     grid-template-columns: 25% 25% 25% 25%;
//     flex-direction: row;
//     width: 100%;
//     justify-content: space-between;
//     padding: 5% 0%;
//   }

  .laurelContainer {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    &:nth-child(1n) {
      flex-basis: 100%;
  }
  }
  
  .laurel {
    width: 18%;
    margin: 1rem 1.5rem;
  }

  @media only screen and (max-width: 600px) {
    .laurelContainer {
      margin: 0 1rem 1rem 0;
    }
    .laurel {
      width: 45%;
      flex: 0.5;
      padding-bottom: 1rem;
      margin: 0
    }  
  }